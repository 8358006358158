import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, Seo } from 'components';
import Header from 'components/Header';
import EmailForm from './components/EmailForm';
import { mobile, useQuery } from 'styles/breakpoints';
import { createLead, updateUser } from 'state/user/effects';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import Text from 'components/Text';
import { usePageView, useQuizData } from 'utils/hooks';
import { Events } from 'utils/events';
import Tracking from 'utils/tracking';

const Container = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 569px;

  @media ${mobile} {
    height: 100vh;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: baseline;
  max-width: 50%;
  flex-direction: column;

  @media ${mobile} {
    max-width: calc(100% - 2rem);
  }
`;

const Title = styled(Text)`
  text-align: center;
  max-width: 440px;
  strong {
    font-family: inherit;
    font-size: inherit;
    font-weight: 700;
  }
  margin-bottom: 1.5rem;

  @media ${mobile} {
    text-align: left;
    font-size: 1.375rem;
  }
`;

const Subtitle = styled(Text)`
  padding-bottom: 0.75rem;
  @media ${mobile} {
    font-size: 0.875rem;
    text-align: left;
    padding-left: 1rem;
  }
`;

const Footer = styled(Text)`
  text-align: center;
  font-family: 'Poppins';
  color: #555770;
  font-size: 0.75rem;
  @media ${mobile} {
    font-size: 0.75rem;
    margin-bottom: 1.5rem;
  }
`;

const ImageContainer = styled.div`
  margin: 4rem 0rem;
  width: 16rem;
  height: 16rem;

  @media ${mobile} {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const EmailFormStyled = styled(EmailForm)`
  margin-top: 0.25rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${mobile} {
    display: unset;
    align-items: unset;
  }
`;

const FooterContainer = styled.div`
  padding: 0 1rem;
  max-width: 485px;
`;

const OnboardingEmail: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { quiz_answers, user } = useSelector((state: AppState) => state.user);
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;

  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-ef';

  const dispatch = useDispatch();
  const { isMobile } = useQuery();

  const { goToCheckout, goToResults, goToBookOnboarding } = useRouter();

  usePageView();

  const handleNextRoute = () => {
    if (variant === 'email-first') {
      return goToBookOnboarding();
    }
    goToBookOnboarding();
  };

  const handleGetResults = async (email: string) => {
    setError('');
    sessionStorage.setItem('userEmail', email);
    setLoading(true);

    Tracking.trackCTAButton(location.pathname);

    if (!user?.email || email !== user.email) {
      dispatch(dispatch(updateUser({ email, password: '' })));
    }

    quiz_answers.quiz = quiz;

    try {
      const code =
        (await dispatch(createLead({ email, funnel: quiz, quiz_answers }))) ??
        '';

      handleNextRoute();
    } catch (error) {
      setError(error?.response.data?.errors?.email ?? '');

      setLoading(false);
    }
  };

  const data = useQuizData('onboardingEmail');

  if (!data) {
    return <LoadingPage />;
  }
  return (
    <>
      <Seo />
      <Container>
        <Header logoVariant={isMobile ? 'left' : 'center'} />
        <ContentContainer>
          <Wrapper>
            <Title
              type="h1"
              color="dark100"
              dangerouslySetInnerHTML={{ __html: `${data?.title}` }}
            />
            <Subtitle type="body" color="dark80">
              {data?.inputLabel}
            </Subtitle>
            <EmailFormStyled
              loading={loading}
              error={error}
              onSubmit={handleGetResults}
              continueBtnTitle={data?.continueBtnTitle}
              placeholder={data?.inputPlaceholder}
              bgColor={`${data?.bgColor}`}
            />
            {data.img ? (
              <ImageContainer>
                <DynamicImage
                  src={data.img}
                  alt={data.alt}
                  width="100%"
                  height="100%"
                />
              </ImageContainer>
            ) : null}
          </Wrapper>
        </ContentContainer>
        <FooterContainer>
          <Footer type="body" color="dark60">
            {data?.footer}
          </Footer>
        </FooterContainer>
      </Container>
    </>
  );
};

export default OnboardingEmail;
